let keyPrefix = "logto";
export class BrowserStorage {
    getKey(item) {
        return void 0 === item ? keyPrefix + ":" + this.appId : keyPrefix + ":" + this.appId + ":" + item;
    }
    async getItem(key) {
        var _globalThis_sessionStorage, _globalThis_localStorage;
        return "signInSession" === key ? null == (_globalThis_sessionStorage = globalThis.sessionStorage) ? void 0 : _globalThis_sessionStorage.getItem(this.getKey(key)) : null == (_globalThis_localStorage = globalThis.localStorage) ? void 0 : _globalThis_localStorage.getItem(this.getKey(key));
    }
    async setItem(key, value) {
        if ("signInSession" === key) {
            sessionStorage.setItem(this.getKey(key), value);
            return;
        }
        localStorage.setItem(this.getKey(key), value);
    }
    async removeItem(key) {
        if ("signInSession" === key) {
            sessionStorage.removeItem(this.getKey(key));
            return;
        }
        localStorage.removeItem(this.getKey(key));
    }
    constructor(appId){
        this.appId = appId;
    }
}
