function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
export function gtag() {
    for(var _window, _dataLayer, _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++)args[_key] = arguments[_key];
    // @ts-ignore
    window.gtag ? window.gtag(...args) : // @ts-ignore
    (null != (_dataLayer = (_window = window).dataLayer) ? _dataLayer : _window.dataLayer = []).push(args);
}
gtag.event = function(name, opt) {
    try {
        gtag("event", name, _extends({}, opt));
    } catch (e) {}
}, //
gtag.set = function(key, value) {
    try {
        gtag("set", key, value);
    } catch (e) {}
} //
;
