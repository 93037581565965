import BaseClient, { createRequester } from "@logto/client";
import { generateCodeChallenge, generateCodeVerifier, generateState } from "./generators";
import { BrowserStorage } from "./storage";
export class LogtoClient extends BaseClient {
    constructor(config, navigate = (url)=>location.href = url){
        super(config, {
            requester: createRequester(fetch),
            navigate,
            storage: new BrowserStorage(config.appId),
            generateCodeChallenge,
            generateCodeVerifier,
            generateState
        }), LogtoClient.instance = this;
    }
}
LogtoClient.instance = null;
